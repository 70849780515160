const $ = require('jquery');

export default class Googlemap {
  constructor(state) {
    if(!$('iframe')[0]) return false;

    $(function() {
      var map = $('iframe');
      map.css('pointer-events', 'none');
      $('.js-googlemap').click(function() {
        map.css('pointer-events', 'auto');
      });
      map.mouseout(function() {
        map.css('pointer-events', 'none');
      });
    })
  }
}
