export default class Video {
  constructor(state) {
    this._state = state;

    // $('.js-video').get(0).play();
    this._open();
    this._close();
  }

  _open() {
    $('.js-playVideo').on(this._state.TOUCH.CLICK, (e) => {
      $('html').addClass('is-showVideo');
      $('.js-video').get(0).pause();
      $('.js-modalVideo').get(0).play();

      return false;
    });
  }

  _close() {
    $('.js-videoClose').on(this._state.TOUCH.CLICK, (e) => {
      $('html').removeClass('is-showVideo');
      $('.js-modalVideo').get(0).pause();
      $('.js-video').get(0).play();

      return false;
    });
  }

}
