import 'slick-carousel';

export default class RouteSlider {
  constructor(state) {
    this._state = state;
    RouteSlider._state = state;
    RouteSlider._current = 0;
    RouteSlider._isSlick = false;
    RouteSlider.device = RouteSlider._state.DEVICE;

    $(window).on('resizeEnd', () => {
      if (!RouteSlider._state.IS_MOBILE) {
        if ($('.js-routeSlider .route').length < 3) {
          return false;
        }
      }

      this._init();
    });
  }

  _init() {
    if (RouteSlider._isSlick) {
      RouteSlider._isSlick = false;
      $('.js-routeSlider').slick('unslick');
    }

    if(RouteSlider._state.IS_MOBILE) {
      if($(".routes_group")[0]) {
        $(".js-routeSlider .route").unwrap();
      }
    } else {
      $('.routes_arrow').attr('style', '');
      do {
        $(".js-routeSlider").children("div.route:lt(3)").wrapAll('<div class="routes_group"></div>')
      } while($(".js-routeSlider").children("div.route").length);
    }

    setTimeout(() => {
      if (!RouteSlider._isSlick) {
        this._slick();
        RouteSlider._isSlick = true;

        if(RouteSlider._state.IS_MOBILE) {
          $('.routes_arrow').css({top: ($('.route_image').height() - 17.5) + 'px'});
        }
      }
    },100);

  }

  _slick() {
    $('.js-routeSlider').on('setPosition', function(event, slick){
      $('.routes_arrow').css({top: ($('.route_image').height()) - ($('.routes_arrow').height()/2)});
    });

    if (RouteSlider._state.IS_MOBILE) {
      if($(".js-spRouteSlider")[0]) {
        $('.js-spRouteSlider').slick({
          prevArrow: '<div class="routes_arrow is-prev"></div>',
          nextArrow: '<div class="routes_arrow is-next"></div>',
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1500,
          fade: true,
          arrows: true,
          dots: false
        });
      }
    }

   $('.js-routeSlider').slick({
     prevArrow: '<div class="routes_arrow is-prev"></div>',
     nextArrow: '<div class="routes_arrow is-next"></div>',
     slidesToShow: 1,
     slidesToScroll: 1,
     speed: 1500,
     fade: true,
     arrows: true,
     dots: false
   });
  }
}
