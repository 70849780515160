const EventEmitter = require('events');

const MATCHED = 'MATCHED';
const UNMATCHED = 'UNMATCHED';

export default class MediaQuery extends EventEmitter {
  /**
   * @param {Array} mediaQueryList
   */
  constructor(mediaQueryList = []) {
    super();
    mediaQueryList.forEach((item) => {
      const mediaQuery = window.matchMedia(item.media);
      mediaQuery.addListener(event => {
        this._handleMatch({
          type: item.type,
          matches: event.matches
        });
      });

      if (mediaQuery.matches) {
        this._current = {
          type: item.type,
          matches: mediaQuery.matches
        };
      }
    });
  }

  dispatchMatch() {
    this._handleMatch(this._current);
  }

  /**
   * @param {Object} action
   */
  _handleMatch(action) {
    let eventName = `${action.type}:${UNMATCHED}`;

    if (action.matches) {
      eventName = `${action.type}:${MATCHED}`;
    }

    this.emit(eventName);
    this._current = action;
  }
}

MediaQuery.MATCHED = MATCHED;
MediaQuery.UNMATCHED = UNMATCHED;
