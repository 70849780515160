const $ = require('jquery');
import anime from 'animejs'

export default class Anchor {
  constructor(state, mediaQuery) {
    this._state = state;

    this._$html = $('html');
    this._$globals = $('.globals');
    this._$header = $('.js-header');

    this.scrollTo();
    this.initializeAnchor();
  }

  scrollTo() {
    $('body').on('click', 'a[rel^="#"], a[rel^="."]', (e) => {
      const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
      let target = $($(e.currentTarget).attr('rel'));
      let top = target.offset().top ? target.offset().top : 0;
      let header_h = this._$header ? (this._$header.height() + 10) : 0;

      if ($(e.currentTarget).hasClass('is-menuAbout')) {
        $('.js-menuTrigger').trigger('click');

        this._$html.removeClass('is-showMenu');
        let top = this._$globals.position().top;

        if (this._state.IS_MOBILE) {
          this._$globals.css({ top: 0 });
          this._$scroller.scrollTop(0);
          $(window).scrollTop(top * -1);
        }
      }

      if (this._state.IS_MOBILE) {
        top = top - 50;
      }

      anime({
        targets: scrollElement,
        scrollTop: top - header_h,
        duration: 1200,
        easing: 'easeInOutCubic'
      });

      return false;
    });
  }

  initializeAnchor() {
    const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
    if (/\?id=\d+/.test(location.search)) {
      let id = location.search.replace(/\?id=(\d+)/, '$1');

      let target = $('.js-anchor-' + id);
      let top = target.offset().top ? target.offset().top : 0;
      let header_h = this._$header.height();

      anime({
        targets: scrollElement,
        scrollTop: top,
        duration: 1200,
        easing: 'easeInOutCubic'
      });
        }
  }
}
