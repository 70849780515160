export default class EventResizeEnd {
  constructor(state) {
    this._state = state;
    let timer  = false;
    let _width = state.WIN_WIDTH;
    let _height = state.WIN_HEIGHT;

    $(window).on('resize', () => {
      if (_width !== $(window).width()) {
        _width = $(window).width();
      }
      else if (_height !== $(window).height()) {
        _height = $(window).height();
      }
      else {
        return false;
      }

      if (timer !== false) {
        clearTimeout(timer);
      }

      timer = setTimeout(function() {
        $(window).trigger('resizeEnd');
      }, 200);
    });
  }
}
