export default class UpdateState {
  constructor(state) {
    $(window).on('resize', () => {
      state.IS_MOBILE    = window.matchMedia('(max-width:767px)').matches;
      state.IS_TABLET    = window.matchMedia('(min-width:768px) and (max-width:1023px)').matches;
      state.IS_DESKTOP   = window.matchMedia('(min-width:1024px)').matches,
      state.WIN_WIDTH    = $(window).width();
      state.WIN_HEIGHT   = $(window).height();
      state.WIN_TOP      = $(window).scrollTop();
      state.ORIENTAION   = window.innerWidth > window.innerHeight ? "LANDSCAPE" : "PORTRAIT";
      state.DEVICE       = state.IS_MOBILE ? 'MOBILE' :  state.IS_TABLET ? 'TABLET' :  state.IS_DESKTOP ? 'DESKTOP' : state.IS_DESKTOP_L ? 'DESKTOP_L' : state.IS_DESKTOP_LL ? 'DESKTOP_LL' : '';
      state.SCROLL_EVENT = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
      state.LANGUAGE     = $('html').data('lang');
    }).trigger('resize');
  }
}
