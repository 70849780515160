const $ = require('jquery');

export default class Header {
  constructor(state) {
    Header.state = state;
    Header._lastY = 0;
    Header._lock = false;
    Header._$hader = $('.js-header');
    Header._changePoint;
    Header._onEnterFrame();

    $(window).on('resizeEnd', () => {
      Header._changePoint = Header.state.IS_MOBILE ? 70 : 120;
    });

    $(window).on("scroll", function() {
    	$(".header").css("left", -$(window).scrollLeft());
    });

  }

  static _init() {
    window.scrollTo(0, 0);
  }

  static _scrollStop() {
    window.cancelAnimationFrame(Header._onEnterFrame);
  }

  static _scrollEasing() {
    Header._scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (Header._lastY < 0 ) { return false; }

    if ( Header._lastY > Header._changePoint) {
      if (!Header._lock) {
        $('html').addClass('is-scroll');
      }
      Header._lock = true;
    }

    if ( Header._lastY < Header._changePoint+20) {
      if (Header._lock) {
        $('html').removeClass('is-scroll');
        // Header._$hader.removeClass('is-fixed');
      }
      Header._lock = false;
    }

    Header._lastY = Header._scrollTop;

  }

  static _onEnterFrame() {
    Header.requestId = requestAnimationFrame(Header._onEnterFrame.bind(Header));
    Header._scrollEasing();
  }
}
