export default class BrowserDetector {
  constructor(state) {
    this._state = state;
    const html = document.getElementsByTagName('html')[0];
    let htmlClass = '';

    // Device
    if (this._state.UA.TABLET) {
      htmlClass += 'tablet';
    } else if (this._state.UA.MOBILE) {
      htmlClass += 'mobile';
    } else {
      htmlClass += 'desktop';
    }

    if (this._state.ORIENTAION === 'LANDSCAPE') {
      htmlClass += ' landscape';
    } else {
      htmlClass += ' portrait';
    }

    // IE
    if (this._state.UA.IE) {
      htmlClass += ' ie';
      if (this._state.UA.IE9) { htmlClass += ' ie9';
      } else if (this._state.UA.IE10) { htmlClass += ' ie10';
      } else if (this._state.UA.IE11) { htmlClass += ' ie11'; }
    }

    if (this._state.UA.EDGE) {
      htmlClass += ' edge';
    }

    // CHROME
    if (this._state.UA.CHROME) {
      htmlClass += ' chrome';
    }

    // SAFARI
    if (this._state.UA.SAFARI) {
      htmlClass += ' safari';
    }

    // FIREFOX
    if (this._state.UA.FIREFOX) {
      htmlClass += ' firefox';
    }

    if(window.devicePixelRatio > 1) {
      htmlClass += ' retina';
    }

    if (this._state.UA.IOS) {
      htmlClass += ' ios';
    }

    if (this._state.UA.ANDROID) {
      htmlClass += ' android';
    }

    $('html').addClass(htmlClass);
  }
}
