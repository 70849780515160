export default class Linkage {
  constructor(state, mediaQuery) {
    this._state = state;

    this._linkage();
  }

  _linkage() {
    $('body')
    .on('mouseenter', '.js-linkage-target', (e) => {
      let el = $(e.currentTarget);
      let parent = el.closest('.js-linkage');

      parent.find('.js-linkage-target').addClass('linkage-on');
    })
    .on('mouseleave', '.js-linkage-target', (e) => {
      let el = $(e.currentTarget);
      let parent = el.closest('.js-linkage');

      parent.find('.js-linkage-target.linkage-on').removeClass('linkage-on');
    })
  }
}
