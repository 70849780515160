export default class Menu {
  constructor(state) {
    this._state = state;

    this._$html = $('html');
    this._$globals = $('.globals');
    this._$target = $('.js-menuTarget');
    this._$trigger = $('.js-menuTrigger');
    this._$scroller = $('.js-menuScroller');

    this._$trigger.on('click', (e) => {
      let showMenu = this._$html.hasClass('is-showMenu');

      if (showMenu) {
        this._$html.removeClass('is-showMenu');
        let top = this._$globals.position().top;

        if (this._state.IS_MOBILE) {
          this._$globals.css({ top: 0 });
          this._$scroller.scrollTop(0);
          $(window).scrollTop(top * -1);
        }
      }
      else {
        let top = $(window).scrollTop();

        if (this._state.IS_MOBILE) {
          this._$globals.css({ top: top * -1 });
          this._$target.css({ top: 0 });
        }
        this._$html.addClass('is-showMenu');

      }
    });
  }

}
