const deviceEvents = {
  touch: typeof document.ontouchstart !== 'undefined',
  pointer: window.navigator.pointerEnabled,
  MSPoniter: window.navigator.msPointerEnabled
};

export default class Touch {
  constructor(e = deviceEvents) {
    this._touch = {
      START: e.pointer ? 'pointerdown' : e.MSPoniter ? 'MSPointerDown' : e.touch ? 'touchstart' : 'mousedown',
      MOVE: e.pointer ? 'pointermove' : e.MSPoniter ? 'MSPointerMove' : e.touch ? 'touchmove' : 'mousemove',
      END: e.pointer ? 'pointerup' : e.MSPoniter ? 'MSPointerUp' : e.touch ? 'touchend' : 'mouseup',
      CLICK: 'click'
    };
  }
}
