export default class UserAgent {
  constructor(ua = window.navigator.userAgent.toLowerCase()) {
    this._ua = {
      ua: ua,
      TABLET: (ua.indexOf('windows') != -1 && ua.indexOf('touch') != -1 && ua.indexOf('tablet pc') == -1)
        || ua.indexOf('ipad') != -1
        || (ua.indexOf('android') != -1 && ua.indexOf('mobile') == -1)
        || (ua.indexOf('firefox') != -1 && ua.indexOf('tablet') != -1)
        || ua.indexOf('kindle') != -1
        || ua.indexOf('silk') != -1
        || ua.indexOf('playbook') != -1,
      MOBILE: (ua.indexOf('windows') != -1 && ua.indexOf('phone') != -1)
        || ua.indexOf('iphone') != -1
        || ua.indexOf('ipod') != -1
        || (ua.indexOf('android') != -1 && ua.indexOf('mobile') != -1)
        || (ua.indexOf('firefox') != -1 && ua.indexOf('mobile') != -1)
        || ua.indexOf('blackberry') != -1,
      IOS: (ua.indexOf('iphone') != -1)
        || ua.indexOf('ipad') != -1
        || ua.indexOf('ipod') != -1,
      IPHONE: (ua.indexOf('iphone') != -1),
      ANDROID: (ua.indexOf('android') != -1) ,
      IE: (ua.indexOf('msie') >= 0 || ua.indexOf('trident') >= 0),
      IE9: (ua.indexOf('msie') > -1) && (ua.indexOf('opera') == -1) && (ua.indexOf('msie 9.') > -1),
      IE10: (ua.indexOf('msie') > -1) && (ua.indexOf('opera') == -1) && (ua.indexOf('msie 10.') > -1),
      IE11: (ua.indexOf('rv 11') > -1) || (ua.indexOf('rv11') > -1) || (ua.indexOf('rv:11') > -1),
      EDGE: (ua.indexOf("applewebkit") >= 0 && ua.indexOf("edge") > -1),
      CHROME: (ua.indexOf('chrome') > -1) && (ua.indexOf('edge') == -1),
      SAFARI: (ua.indexOf('safari') > -1) && (ua.indexOf('chrome') == -1),
      FIREFOX: (ua.indexOf('firefox') > -1)
    };
  }
}
