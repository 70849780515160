export default class EqualHeight {
  constructor(state) {
    this._state = state;

    $(window).on('load resizeEnd', () => {
      this._event();
    });
  }

  _event() {
    $('.js-equalHeight').each((_i, _el) => {
      let device = this._state.DEVICE.toLowerCase();
      const _col = $(_el).data(device + '-col') ? $(_el).data(device + '-col') : '';

      if (!_col) {
        return false;
      }

      const _$target = $(_el).find('.js-equalHeightTarget').css({height: 'auto'});

      if (_col) {
        let _group = [];
        for (var __i = 0; __i < _$target.length; __i++) {
          const _n = Math.floor(__i / _col);
          if (!_group[_n]) _group[_n] = [];
          _group[_n].push(_$target[__i]);
        }

        $.each(_group, (_i, _el) => {
          this._set(_el);
        });
      }
      else {
        this._set(_$target);
      }
    });
  }

  _set(_el) {
    let _h = 0;
    $(_el).each((_i, __el) => {
      const __h = $(__el).height();
      if (_h < __h) _h = __h;
    }).height(_h);
  }
}
