import 'slick-carousel';

export default class ArticleSlider {
  constructor(state) {
    this._state = state;
    ArticleSlider._state = state;
    ArticleSlider._current = 0;
    ArticleSlider._isSlick = false;
    ArticleSlider.device = ArticleSlider._state.DEVICE;

    $(window).on('resizeEnd', () => {
      if (!ArticleSlider._state.IS_MOBILE) {
        if ($('.js-articleSlider .article').length < 2) {
          return false;
        }
      }
      this._init();
    });
  }

  _init() {
    if (ArticleSlider._isSlick) {
      ArticleSlider._isSlick = false;
      $('.js-articleSlider').slick('unslick');
    }

    if(ArticleSlider._state.IS_MOBILE) {
      if($(".articles_group")[0]) {
        $(".js-articleSlider .article").unwrap();
      }

    } else {
      $('.articles_arrow').attr('style', '');

      do {
        $(".js-articleSlider").children("div.article:lt(2)").wrapAll('<div class="articles_group"></div>')
      } while($(".js-articleSlider").children("div.article").length);
    }

    setTimeout(() => {
      if (!ArticleSlider._isSlick) {
        this._slick();
        ArticleSlider._isSlick = true;

        if(ArticleSlider._state.IS_MOBILE) {
          $('.articles_arrow').css({top: ($('.article_image').height() - 17.5) + 'px'});
        }
      }
    },100);

  }

  _slick() {
    if (ArticleSlider._state.IS_MOBILE) {
      if($(".js-spArticleSlider")[0]) {
        $('.js-spArticleSlider').slick({
          prevArrow: '<div class="articles_arrow is-prev"></div>',
          nextArrow: '<div class="articles_arrow is-next"></div>',
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1500,
          fade: true,
          arrows: true,
          dots: false
        });
      }
    }

   $('.js-articleSlider').slick({
     prevArrow: '<div class="articles_arrow is-prev"></div>',
     nextArrow: '<div class="articles_arrow is-next"></div>',
     slidesToShow: 1,
     slidesToScroll: 1,
     speed: 1500,
     fade: true,
     arrows: true,
     dots: false
   });
  }
}
