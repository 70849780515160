import 'slick-carousel';

export default class ArticleMain {
  constructor(state) {
    this._state = state;
    ArticleMain._state = state;
    ArticleMain._current = 0;
    this._slick();
  }

  _slick() {
   $('.js-articleMainSlider').slick({
     prevArrow: '<div class="articleMainImages_arrow is-prev"></div>',
     nextArrow: '<div class="articleMainImages_arrow is-next"></div>',
     speed: 1500,
     slidesToShow: 1,
     fade: true,
     arrows: true,
     dots: true,
     autoplay: true,
     pauseOnHover: false
   });
  }
}
