export default class Viewport {
  constructor(state, width) {
    $('meta[name="viewport"]').remove();
    $('head').append(state.UA.MOBILE || state.IS_MOBILE ?
      '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">' :
      ('<meta name="viewport" content="width=' + width + '">')
    );
  }

  resize(state, width) {
    $(window).on('resizeEnd', () => {
      $('meta[name="viewport"]').remove();
      $('head').append(state.UA.MOBILE || state.IS_MOBILE ?
        '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">' :
        ('<meta name="viewport" content="width=' + width + '">')
      );
    });
  }
}
