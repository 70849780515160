const objectAssign = require('object-assign');

export default class Clamp {
  constructor(state) {
    let clamp = this;

    clamp.state = state;

    clamp.options = {
      line: 2,
      performance: 10,
      truncationChar: '...'
    };

    $.fn.clamp = function (options) {
      clamp.options = objectAssign(clamp.options, options);
      clamp.$elements = this;

      clamp._cut();

      $(window).trigger('clampEnd');
    }

    let $clamp = $('.js-clamp');
    if ($clamp.length) {
      $(window).on('resizeEnd', () => {
        $clamp.clamp();
      });
    }
  }

  _cut() {
    this.$elements.each((i, element) => {
      let device = this.state.DEVICE.toLowerCase();
      this.options.line = $(element).attr('data-clampline-' + device) ? $(element).attr('data-clampline-' + device) : this.options.line;

      let originalText = element.dataset.originalText ? element.dataset.originalText : (element.dataset.originalText = element.innerText);
      element.innerText = originalText;

      let currentLine = this._checkLine(element);

      if (currentLine <= this.options.line) {
        return true;
      }

      let splitStrings = this._split(originalText, this.options.performance).reverse();
      let splitStrings_n = splitStrings.length - 1;
      let j = splitStrings.length - 1;

      splitStrings.every((splitString) => {
        let _reg = new RegExp(splitString.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '$');
        originalText = originalText.replace(_reg, '');
        element.innerText = originalText;

        if (this._checkLine(element) <= this.options.line) {
          this._trimming(element, splitStrings[splitStrings_n - j]);
          return false;
        }

        j--;

        return true;
      });
    });

    return this.$elements;
  }

  _trimming(element, string) {
    let tmpStr = element.innerText;

    for(let i = (string.length - 1); i > 0; i--) {
      let newStr = tmpStr + string.slice(0, i) + this.options.truncationChar;
      element.innerText = newStr;

      if (this._checkLine(element) <= this.options.line) {
        break;
      }
      else if (i == 1) {
        newStr = tmpStr + this.options.truncationChar;
        element.innerText = newStr;
        if (!(this._checkLine(element) <= this.options.line)) {
          element.innerText = tmpStr.replace(/.{1}$/, '') + this.options.truncationChar;
        }
      }
    }
  }

  _split(originalText, performance) {
    let reg = new RegExp(".{1," + performance + "}", 'g');
    let matches = originalText.match(reg);

    if (matches) {
      matches.forEach((str, i) => {
        let match = str.match(/^[、|。]/);
        if (match) {
          matches[i - 1] = matches[i - 1] + match[0];
          matches[i] = str.replace(/^[、|。]/, '');
        }
      });

      return matches;
    }

    return [];
  }

  _checkLine(element) {


    // console.log(element, element.clientHeight, parseFloat(window.getComputedStyle(element, null).lineHeight), element.clientHeight / parseFloat(window.getComputedStyle(element, null).lineHeight));

    // console.log(
    //   parseFloat(window.getComputedStyle(element, null).fontSize),
    //   parseFloat(window.getComputedStyle(element, null).lineHeight)
    // );

    // console.log(Math.floor(
    //   element.clientHeight /
    //     parseInt(
    //       element.currentStyle ?
    //         parseFloat(element.currentStyle.fontSize) * parseFloat(element.currentStyle.lineHeight) :
    //         // parseFloat(window.getComputedStyle(element, null).fontSize) * parseFloat(window.getComputedStyle(element, null).lineHeight)
    //         parseFloat(window.getComputedStyle(element, null).lineHeight)
    //     )
    // ));
    // console.log(element, window.getComputedStyle(element, null).lineHeight);

    return Math.floor(
      element.clientHeight /
        parseInt(
          element.currentStyle ?
            parseFloat(element.currentStyle.fontSize) * parseFloat(element.currentStyle.lineHeight) :
            // parseFloat(window.getComputedStyle(element, null).fontSize) * parseFloat(window.getComputedStyle(element, null).lineHeight)
            parseFloat(window.getComputedStyle(element, null).lineHeight)
        )
    );
  }

}
